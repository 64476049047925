import React, { Component } from "react";
// import Logo from "../assets/th.jpg";
// import Logo2 from "../assets/logo.svg";

import "../styles/Home.css";

function Home() {

  
  return (

    

    <div className="home" id="home" >

      <div className="headerContainer">
        <div ></div>
        
        <p> *Your New Website Design 1.0 </p>
        <i>
          <p>   *We Design And Host Your Website For Half The Price. </p>
          </i>
        <p> Your Headline</p>
        <p>  Happy Hour Times </p>
        <p>  Hours Of Operation </p>
      </div>
        <div>
        
      </div>

    </div>
  );
}


export default Home;
