import React, { useState } from 'react';
import { Carousel } from 'react-carousel-minimal';
import Data from "../helpers/ImageCarousel";


function Slider() { 
  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
    <div className="App">
      <div style={{ textAlign: "center" }}>
        <h2>See What We Do</h2>
        <p>*Easy to use, responsive and customizable. Eye catching and never boring.</p>
        <p>*Mobile and desktop friendly - Test it out</p>
        <p>  *Images Of Happy Faces, Dancing, Drinks, ... </p>
        <div style={{
          padding: "0 20px"
        }}>
          <Carousel
            data={Data}
            time={3000}
            width="850px"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={false}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "40px auto",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Slider;