import Pepperoni from "../assets/catchitquick.jpg";
import Margherita from "../assets/rpc.jpg";
import PedroTechSpecial from "../assets/cdc.jpg";
// import Vegan from "../assets/bitcoin_logo.svg";
import Pineapple from "../assets/catchitquick.jpg";
import Expensive from "../assets/rpc.jpg";

export const PodcastList = [
  {
    name: "Name",
    image: PedroTechSpecial,
    linkTo: "https://cellardoorcider.com/",
    price: 15.99,
    description: "Description tempor incididunt ut labore."  
  },
  {
    name: "Name",
    image: Expensive,
    linkTo: "https://reddingcooppreschool.org/",
    price: 11.99,
    description: "All sites are desktop and mobile friendly for ease of access on all devices."  
  },
  {
    name: "Name",
    image: Pepperoni,
    linkTo: "http://catchitquickjuggling.com/",
    price: 256.53,
    description: "Make a good first impression.  All sites have zero ads, so clients will stay focused on your content."  
  },
//   {
//     name: "Vegan Pizza",
//     image: Vegan,
//     price: 17.99,
//     description: "Pizza without cheese?..."  
//   },
//   {
//     name: "Pineapple Pizza",
//     image: Pineapple,
//     price: 4.99,
//     description: "Fruit on Pizza?...."  
//   },
//   {
//     name: "Very Expensive Pizza",
//     image: Expensive,
//     price: 1997.99,
//     description: "Kind of expensive, but you only live once.  YOLO. "  
//   },
];
