import "./App.css";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Podcast from "./pages/Podcast";
import Navbar2 from "./components/Navbar2";
import Community from "./pages/Community";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar2 />
        <Home />
        <About />
        <Community />
        <Podcast />
        <Contact />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
