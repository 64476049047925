import React, { useRef } from "react";
import Pizza from "../assets/meetupIcon2.svg";
import "../styles/Community.css";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";

function Community() {
  
  
  return (
    <div className="top">
      <div className="communityRight" id="community">
          <div>
            <h3> Check Out Our Meetup Groups And Events </h3>
          <div className="socialMedia">
            <a href="https://www.meetup.com/">

              <img src={Pizza} 
              ></img>
            </a>

            <a href="https://twitter.com/">
              <InstagramIcon /> 
            </a>
            <a href="https://twitter.com/">
                <TwitterIcon /> 
            </a>
            <FacebookIcon />
      </div>
          </div>

      <div>
        
      
    </div>
    </div>
    </div>




);
}

  
  export default Community;