import React, { Component } from "react";
import { Link } from "react-router-dom";


function PodcastItem({ image, name, description,linkTo, price }) {
  return (
    <div className="podcastItem">

        <div className="leftSide">
          <a href={linkTo}>
            <div style={{ backgroundImage: `url(${image})` }}> </div>
          </a> 

        </div>
        
        <div className="rightSide">
          <a href={linkTo}>
            <h3> {name} </h3>
            <p> {description} </p>
          </a> 
        </div>
      
      {/* <p> ${price} </p> */}
    </div>
  );
}

export default PodcastItem;